var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.pageInfo.viewType === 'CASE')?_c('div',[_c('Title',{attrs:{"data":_vm.pageInfo}}),_c('Cases',{attrs:{"data":_vm.pageInfo}})],1):(_vm.pageInfo.viewType === 'TOOLBOX')?_c('div',[_c('Title',{attrs:{"data":_vm.pageInfo}}),_c('Toolbox')],1):(_vm.pageInfo.viewType === 'LIBRARY')?_c('div',[_c('Title',{attrs:{"data":_vm.pageInfo}}),_c('Library')],1):(_vm.pageInfo.viewType === 'NEWS')?_c('div',[_c('Title',{attrs:{"data":_vm.pageInfo}}),(_vm.isShow)?_c('News'):_vm._e()],1):(_vm.pageInfo.viewType === 'EDUCATION')?_c('div',[_c('Title',{attrs:{"data":_vm.pageInfo}}),_c('Education')],1):(_vm.pageInfo.viewType === 'HOME')?_c('div',[_c('Home')],1):(_vm.pageInfo.viewType === 'INTERNAL')?_c('div',[_c('Iframe')],1):(_vm.pageInfo.viewType === 'MARKDOWN')?_c('div',[_c('DTitle',{attrs:{"data":_vm.pageInfo}}),_c('Markdown',{attrs:{"data":_vm.pageInfo.markdown}}),(_vm.pageInfo.blocks)?_c('Block',{attrs:{"data":_vm.pageInfo.blocks}}):_vm._e()],1):(_vm.pageInfo.viewType === 'DOWNLOAD')?_c('div',[_c('DTitle',{attrs:{"data":_vm.pageInfo}}),_c('Download')],1):(_vm.pageInfo.viewType === 'TY_ACCOUNT')?_c('div',[(this.$route.query.path)?_c('iframe',{staticStyle:{"background-size":"100% 100%","width":"100%","height":"800px","border":"none"},attrs:{"id":"ticketIFrame","src":'https://tongyuan.cc:9991/ticket/' +
        this.$route.query.path +
        '?access_token=' +
        _vm.token +
        '&product=' +
        _vm.product +
        '&software=' +
        _vm.software +
        '&sysinfo=' +
        _vm.sysinfo}}):_c('iframe',{staticStyle:{"background-size":"100% 100%","width":"100%","height":"800px","border":"none"},attrs:{"id":"ticketIFrame","src":'https://tongyuan.cc:9991/ticket/mine' + '?access_token=' + _vm.token}})]):(_vm.pageInfo.viewType === 'SAFETY')?_c('div',[_c('DTitle',{attrs:{"data":_vm.pageInfo}}),(_vm.pageInfo.blocks)?_c('Block',{attrs:{"data":_vm.pageInfo.blocks}}):_vm._e()],1):(this.$route.path.includes('videoPlay'))?_c('div',[_c('VideoPlay')],1):_c('div',[(_vm.pageInfo.router === '/solution')?_c('SolutionTitle',{attrs:{"data":_vm.pageInfo}}):(_vm.pageInfo.title)?_c('Title',{attrs:{"data":_vm.pageInfo}}):_vm._e(),(_vm.pageInfo.blocks)?_c('Block',{attrs:{"data":_vm.pageInfo.blocks}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }