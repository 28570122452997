<template>
    <div>
        <list :data="toolbox"></list>
    </div>
</template>

<script>
import list from "@/components/block/toolbox.vue";
export default {
    components: {
        list
    },
    data() {
        return {
            toolbox: {
                items: []
            }
        };
    },
    created() {
        this.getToolboxList();
        console.log(this.toolbox)
    },
    methods: {
        async getToolboxList() {
            const res = await this.$api.getToolboxList();
            if (res.code == 200) {
                this.toolbox.items = res.data;
            }
        },
    },
};
</script>

<style></style>
