<template>
    <div class="body">
        <div class="wrap">
            <div class="title">{{ videoList.name }}</div>
            <div class="content">
                <div class="video-wrap">
                    <!-- <video id="courseVideo" controlslist="nodownload" controls="controls" :src="this.videoUrl" width="100%"
                        v-if="this.videoUrl" preload="auto" height="100%">
                        <source :src="this.videoUrl" type="video/mp4" />
                        您的浏览器不支持 video 标签。
                    </video> -->
                    <video
                    id="courseVideo"
                    controlslist="nodownload"
                    controls="controls"
                    :src="videoUrl"
                    width="100%"
                    v-if="videoUrl"
                    preload="auto"
                    height="100%"
                    ref="video"
                    @timeupdate="updateTime"
                    @canplay="onCanplay"
                    @ended="onEnded"
                    @pause="onPause"
                    @playing="onPlaying"
                    @waiting="onWaiting"
                    >
                    您的浏览器不支持 video 标签。
                    </video>
                    <div v-else class="none">
                        暂无视频......
                    </div>
                </div>
                <div class="menu-wrap">
                    <div class="menu-list">
                        <div v-if="videoList.chapterFlag">
                            <div class="menu-title" v-for="(item, index) of videoList.courseWithChapter" :key="index">
                                <span class="name">{{ item.chapterName }}</span>
                                <div v-for="(chapter, index) of item.videoList" :key="index" class="sub-name"
                                    @click="playVideo(chapter.url)" :class="{ active: getCurrentMenu(chapter) }">
                                    <span>{{ chapter.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="menu-title" @click="playVideo(item.url)" :class="{ active: getCurrentMenu(item) }"
                                v-for="(item, index) of videoList.courseWithoutChapter" :key="index">
                                <span class="name">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="introduce-wrap">
                <span>课程介绍：</span>
                <span style="white-space: pre-line;">{{ videoList.introduce }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    courseId: String
  },
  data() {
    return {
      videoId: 'video' + new Date().getTime(),
      videoList: {},
      videoUrl: '',
      currentMenu: '',
      time: 0, // 累积播放时长
      // lastTime:0, //上次播放时长
      lastTime: 0,
      currentTime: 0, // 当前播放的时间点（秒）
      intervalId: null,
      isPlaying: false, // 标记当前是否正在播放
      userInfo: null
    };
  },
  created() {
    this.getVideoList(this.$route.query.courseId);
    document.documentElement.scrollTop = 0;
    this.userInfo = this.$utils.getStorage('userInfo');
  },
  computed: {
    getCurrentMenu() {
      return (item) => {
        if (this.currentMenu === item.url) {
          return true;
        }
        return false;
      };
    }
  },
  mounted() {
    // this.initVideo();
    window.addEventListener('beforeunload', this.onBeforeUnload);
  },
  methods: {
    loadData() {
      let path = '';
      if (this.videoList.chapterFlag && this.videoList.courseWithChapter[0].videoList[0]) {
        path = this.videoList.courseWithChapter[0].videoList[0].url;
      } else if (this.videoList.courseWithoutChapter[0]) {
        path = this.videoList.courseWithoutChapter[0].url;
      }
      this.currentMenu = path;
      this.playVideo(path);
    },
    playVideo(path) {
      this.currentMenu = path;
      if (path) {
        if (path.includes('.mp4') || path.includes('.m4v')) {
          this.videoUrl = window.location.origin +
                        `/official/course/getMP4FileBytesByRange?path=${path}`;
        }
      } else {
        this.videoUrl = null;
      }
    },
    async getVideoList(id) {
      const res = await this.$api.getVideoList({ id: id });
      if (res.code == 200) {
        this.videoList = res.data;
        document.title = this.videoList.name ? this.videoList.name + '-苏州同元软控' : '培训教程-苏州同元软控';
        console.log(res.data, 'videoList');
        this.loadData();
      }
    },
    updateTime() {
      this.currentTime = this.$refs.video.currentTime; // 获取当前播放时间
    },
    countTime() {
      console.log('this.time' + this.time);
    },
    onCanplay() {
      console.log('canplay');
    },
    onEnded() {
      console.log('ended');
      clearInterval(this.intervalId);
      console.log('this.time' + this.time);
    },
    onPause() {
      console.log('pause');
      clearInterval(this.intervalId);
      console.log('this.time' + this.time);
    },
    onPlaying() {
      console.log('playing');
      this.intervalId = setInterval(() => {
        this.time += 0.25;
      }, 250);
    },
    onWaiting() {
      console.log('waiting');
      clearInterval(this.intervalId);
      // 在这里向后台发送数据
      console.log('this.time' + this.time);
    },
    async addEventMessage() {
      const currentDate = new Date();
      const formattedTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
      let obj = {};
      if (this.userInfo) {
        obj = {
          courseId: this.videoList.courseWithoutChapter[0].courseId,
          event: 'course',
          eventTime: formattedTime,
          message: {
            videoTime: this.time,
            courseId: this.videoList.courseWithoutChapter[0].courseId
          },
          userName: this.userInfo.sub
        };
      } else {
        obj = {
          courseId: this.videoList.courseWithoutChapter[0].courseId,
          event: 'course',
          eventTime: formattedTime,
          message: {
            videoTime: this.time,
            courseId: this.videoList.courseWithoutChapter[0].courseId
          }
        };
      }
      console.log(obj + '$api');
      // window.navigator.sendBeacon('http://172.16.1.146:9400/official/event/addEventMessage', JSON.stringify(obj));
      const res = await this.$api.addEventMessage(obj);
      if (res.code == 200) {
        console.log(res + '成功');
      }
    },
    onBeforeUnload() {
      console.log('onBeforeUnloadonBeforeUnload');
      this.onEnded();
      const currentDate = new Date();
      const formattedTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
      let obj = {};
      if (this.userInfo) {
        obj = {
          courseId: this.videoList.courseWithoutChapter[0].courseId,
          event: 'course',
          eventTime: formattedTime,
          message: {
            videoTime: this.time,
            courseId: this.videoList.courseWithoutChapter[0].courseId
          },
          userName: this.userInfo.sub
        };
      } else {
        obj = {
          courseId: this.videoList.courseWithoutChapter[0].courseId,
          event: 'course',
          eventTime: formattedTime,
          message: {
            videoTime: this.time,
            courseId: this.videoList.courseWithoutChapter[0].courseId
          }
        };
      }
      console.log('obj' + obj + 'navigator.sendBeacon' + process.env.VUE_APP_STATE);
      // window.navigator.sendBeacon('http://172.16.1.146:9400/official/event/addEventMessage', JSON.stringify(obj));
      window.navigator.sendBeacon(process.env.VUE_APP_STATE + '/official/event/addEventMessage', JSON.stringify(obj));
      // window.navigator.sendBeacon('https://www.tongyuan.cc/official/event/addEventMessage', JSON.stringify(obj));
      // 统计视频的播放时长
      // this.addEventMessage();
    }
  },
  beforeDestroy() {
    // 统计视频的播放时长
    this.addEventMessage();
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    this.onEnded(); // 确保清理定时器和发送数据
    console.log('关闭页面' + this.time);
  }

};
</script>

<style lang="scss" scoped>
.body {
    background: #282828;
    padding: 20px 0 110px 0;
    color: #ffffff;
    min-height: 850px;

    .wrap {
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 20px;
    }

    .title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .content {
        display: flex;
        margin-bottom: 10px;
        max-height: 520px;
    }

    .video-wrap {
        width: 75%;
        aspect-ratio: 16 / 9;
        background-color: #000000;

        .none {
            height: 100%;
            background-color: #524f4f;
            color: #909090;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .menu-wrap {
        flex: 1;
        min-height: 0px;
        background-color: #383838;
        padding-top: 20px;
        overflow: auto;
        min-width: 100px;
    }

    .menu-list {
        .menu-title {
            color: #909090;
            font-weight: normal;
            line-height: 26px;
            cursor: pointer;
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 4px;
            padding: 0 20px;

            &.active {
                background-color: #282828;
            }
        }

        .sub-name {
            cursor: pointer;
            padding-left: 10px;

            &.active {
                background-color: #282828;
            }
        }

        .name {
            cursor: pointer;
        }
    }

    .introduce-wrap {
        color: #909090;
        font-size: 15px;
    }
}

@media screen and (max-width: 700px) {
    .body {
        min-height: 500px;

        .wrap {
            width: 96%;
        }

        .content {
            max-height: 300px;
        }

        .menu-list {
            .menu-title {
                font-size: 10px;
                padding: 0 10px;
            }
        }
    }
}</style>
