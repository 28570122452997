<template>
    <div class="body" :style="{
        backgroundImage: 'url(' + data.backgroundCover + ')',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }">
        <div class="title" v-if="data.title">
            <!-- {{ data.title }} -->
            <span style="color:#fcaa00 ;">{{ data.title }}</span> <span v-if="data.router=='/download'">已上线</span>
        </div>
        <div class="desc" v-if="data.description">
            {{ data.description }}
        </div>
        <div class="button" v-if="data.productId">
            <el-dropdown trigger="click" split-button v-if="product.downloadFlag && product.downloadList.length">
                <a :href="downloadUrl" @click="handleDownload(product.downloadList[0])">
                    <div style="color: white;width: 160px;height: 100%;padding-top: 7%;">
                        立即下载
                        {{ title }}
                    </div>
                </a>
                <el-dropdown-menu slot="dropdown" :append-to-body="false">
                    <div style="color: darkgray;display: flex;padding: 0 20px;">
                        <div style="width: 150px;"></div>
                        <div style="width: 75px;text-align: center;">直接下载</div>
                        <div style="width: 55px;text-align: center;">百度网盘</div>
                    </div>
                    <el-dropdown-item v-for="(button, index) of product.downloadList" :key="index">
                        <div style="display: flex;">
                            <div class="system-name" :title="button.systemName">{{ button.systemName }}</div>
                            <div class="system-bit">{{ button.systemBit }}</div>
                            <a :href="button.url ? button.url : null" style="width: 75px;text-align: center;" @click="handleDownload(button)">
                                <i class="el-icon-download" style="color: #497ddc;font-size: 18px;font-weight: bold;"></i>
                            </a>
                            <a :href="button.baiduNetdisk" style="width: 55px;text-align: center;" @click="handleDownload(button)">
                                <i class="el-icon-download" style="font-size: 18px;font-weight: bold;"></i>
                            </a>
                        </div>
                        <el-divider v-if="index + 1 < product.downloadList.length"></el-divider>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <a v-else-if="product.buttonName" :href="product.buttonRouter" style="color: white;">
                <el-button style="width: 150px;">
                    {{ product.buttonName }}
                </el-button>
            </a>
            <div style="margin: 10px 0">
                <span>{{ product.runtimeEnv }} </span>
                <span v-if="product.runtimeEnv">更新日期：{{ this.updateTime }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      title: '',
      downloadUrl: '',
      updateTime: '',
      product: {},
      userInfo: null
    };
  },
  created() {
    if (this.data.productId) {
      this.getProductInfo({ id: this.data.productId });
    }
    console.log('this.data', this.data);
    this.userInfo = this.$utils.getStorage('userInfo');
  },
  methods: {
    async getProductInfo(params) {
      const res = await this.$api.getProductInfo(params);
      if (res.code == 200) {
        this.product = res.data;
        this.updateTime = this.product.updateTime.substr(0, 10);
        if (this.product.downloadList[0]) {
          this.title = this.product.downloadList[0].systemName;
          this.downloadUrl = this.product.downloadList[0].url;
        }
      }
    },
    async handleDownload(button) {
      const currentDate = new Date();
      const formattedTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
      let obj = {};
      if (this.userInfo) {
        obj = {
          downloadId: button.id,
          event: 'download',
          eventTime: formattedTime,
          message: {
            downloadId: button.id,
            productId: button.productId
          },
          userName: this.userInfo.sub
        };
      } else {
        obj = {
          downloadId: button.id,
          event: 'download',
          eventTime: formattedTime,
          message: {
            downloadId: button.id,
            productId: button.productId
          }
        };
      }
      console.log('obj111', obj);
      const res = await this.$api.addEventMessage(obj);
      if (res.code == 200) {
        console.log(res + '成功');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.body {
    height: 300px;
    width: 100%;
    color: white;
    text-align: center;
    padding-top: 60px;
    white-space: pre-line;

    .title {
        font-size: 42px;
        font-weight: normal;
        padding-top: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .desc {
        width: 92%;
        max-width: 850px;
        font-size: 20px;
        margin: 10px auto;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .icon img {
        max-width: 100%;
    }
}

/deep/ .el-dropdown-menu__item i {
    margin-right: 0;
}

.system-name {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    width: 100px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
}
.system-bit {
    font-size: 16px;
    width: 50px;
    text-align: center;
}

/deep/ .el-button--default {
    height: 40px;
    background-color: #497ddc;
    border-radius: 6px;
    font-size: 15px;
    color: white;
    font-weight: 500;
    padding: 0;
    border: none;

    &:hover {
        background-color: #5e8bde;
    }
}

/deep/ .el-dropdown .el-dropdown__caret-button {
    width: 30px;
}

/deep/ .el-dropdown .el-button-group {
    display: flex;
}

/deep/ .el-popper .popper__arrow{
    border: none;
}

/deep/ .el-popper .popper__arrow::after{
    border: none;
}

/deep/ .el-popper {
    margin-top: 8px;
}

/deep/ .el-divider--horizontal {
    margin: 2px 0;
}

::v-deep .el-dropdown-menu {
    position: absolute !important;
    top: 35px !important;
    // left: 0 !important;
}

@media screen and (max-width: 700px) {
    .body {
        height: 280px;
        padding-top: 40px;

        .title {
            font-size: 30px;
        }
    }
}

/deep/ .el-dropdown-menu {
    transform:translateX(0);
    z-index: 1990 !important;
}
</style>
