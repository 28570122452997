<template>
  <div class="home">
    <Block :data="pageInfo.blocks" v-if="pageInfo.blocks"></Block>
  </div>
</template>

<script>
import Block from "../block";
export default {
  components: {
    Block
  },
  data() {
    return {
      pageInfo: {},
      userInfo: null,
    };
  },
  created() {
    this.userInfo = system.userInfo;
    this.getHomePage();
  },
  methods: {
    async getHomePage() {
      const res = await this.$api.getHomePage();
      if (res.code == 200) {
        this.pageInfo = res.data;
        // this.pageInfo.blocks = this.blocks;
        console.log(this.pageInfo);
      }
    },
  },
};
</script>
