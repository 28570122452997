<template>
  <div class="body" :style="{
    backgroundImage: 'url(' + data.backgroundCover + ')',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }">
    <div class="icon" v-if="data.icon">
      <img :src="data.icon" />
    </div>
    <div class="title" v-if="data.title">
      {{ data.title }}
    </div>
    <div class="desc" v-if="data.description">
      {{ data.description }}
    </div>
    <div class="button" v-if="data.releaseNoteRouter">
      <a :href="data.point ? data.releaseNoteRouter + '#' + data.point : data.releaseNoteRouter">
        <el-button :style="{ background: data.releaseNoteButtonColor }">{{ data.releaseNoteButtonName }}</el-button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      background: "",
      titleInfo: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.body {
  height: 580px;
  width: 100%;
  color: white;
  text-align: center;
  padding-top: 135px;
  white-space: pre-line;

  .title {
    font-size: 48px;
    font-weight: normal;
    padding-top: 55px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .desc {
    width: 92%;
    max-width: 850px;
    line-height: 36px;
    font-size: 20px;
    margin: 60px auto 0 auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .button {
    margin-top: 60px;
  }

  /deep/ .el-button {
    color: #fff;
    border: none;
    font-size: 20px;
    margin: 0 auto;
    padding: 15px 40px;
    border-radius: 30px;
  }

  .icon img {
    max-width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .body {
    padding-top: 60px;
    height: 300px;

    .title {
      font-size: 34px;
      padding-top: 10px;
    }

    .desc {
      font-size: 17px;
      margin: 10px auto 0 auto;
    }

    .button {
      margin-top: 20px;
    }

    /deep/ .el-button {
      padding: 2vw 5vw;
      font-size: 3vw;
    }
  }
}
</style>
