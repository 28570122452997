<template>
  <div>
    <div v-show="this.isShow">
      <div v-for="(item, index) in news" :key="index">
        <div v-if="index === 0" style="background-color: #f7f7f7;">
          <div class="top">
            <div class="news_box" @click="newPage(item.id)">
              <div class="left">
                <img :src="item.cover" />
              </div>
              <div class="right">
                <div class="right_one">
                  <div class="title">{{ item.title }}</div>
                  <div class="content">{{ item.description }}</div>
                </div>
                <div class="right_two">
                  <span>
                    <i class="el-icon-time" v-if="item.publishDate"></i>
                    {{ item.publishDate }}
                  </span>
                  <span class="source" v-if="item.source">来源:{{ item.source }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="middle">
            <div class="list_box" @click="newPage(item.id)">
              <div class="m_img">
                <img :src="item.cover" />
              </div>
              <div class="right2">
                <div class="title2">{{ item.title }}</div>
                <div class="content2">{{ item.description }}</div>
                <div class="button">
                  <el-button size="mini" @click="newPage(item.id)">view+</el-button>
                </div>
              </div>
              <div class="pubdate" v-if="item.publishDate">
                <div class="year">{{ item.publishDate.substr(0, 4) }}</div>
                <div class="date">{{ item.publishDate.substr(5) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-pagination :current-page="pageParam.pageIndex" background :page-size="pageParam.pageSize"
          :total="pageParam.total" layout="prev,pager, next" @current-change="changePage">
        </el-pagination>
      </div>
    </div>
    <div v-show="!this.isShow" style="background-color: #f7f7f7;padding: 30px 0;">
      <div class="body">
        <div class="title">{{ detailData.title }}</div>
        <div class="mid-title-p">
          <div class="mid-title-c">
            <div style="font-size: 18px;" v-if="detailData.publishDate">
              <i class="el-icon-time"></i>
              {{ detailData.publishDate }}
            </div>
            <div style="font-size: 16px;" v-if="detailData.source">来源：{{ detailData.source }}</div>
          </div>
        </div>
        <div class="content">
          <MarkDown :mode="mode" v-model="detailData.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MarkDown from '@/components/markdown';
// import { clearStorage } from '@/core/utils';
export default {
  components: {
    MarkDown
  },
  data() {
    return {
      mode: 'preview',
      // 新闻列表
      news: [],
      pageParam: {
        pageIndex: 1,
        // 第几页
        pageSize: 5,
        // 每页几条
        total: 100
      },
      isShow: true,
      selectId: 3,
      detailData: {},
      currentPath: ''
    };
  },
  created() {
    this.currentPath = this.$route;
    // console.log('this.currentpath', this.currentPath);
    this.getNewsList();
    this.getNews({ id: this.selectId });
  },
  watch: {
    'currentPath.query': {
      handler(newQuery, oldQuery) {
        const isEmpty = Object.keys(newQuery).length !== 0;
        this.isShow = !isEmpty;
        if (this.isShow == false) {
          this.getNews({ id: this.currentPath.query.id });
        }
      },
      deep: true
    }
  },
  methods: {
    // 分页页数变化
    changePage(newPage) {
      this.pageParam.pageIndex = newPage;
      this.getNewsList();
    },
    // 获取新闻列表
    async getNewsList() {
      const res = await this.$api.getNewsList(this.pageParam);
      if (res.code === 200) {
        res.data.forEach(item => {
          if (item.publishDate) {
            item.publishDate = moment(item.publishDate).format('YYYY.MM.DD');
          }
        });
        this.news = res.data;
        this.pageParam.total = Number(res.total);
        // console.log('列表', res);
      }
    },
    // 点击详情
    newPage(id) {
      this.selectId = Number(id);
      this.$router.push({
        path: '/news',
        query: { id: this.selectId }
      });
    },
    async getNews(id) {
      const res = await this.$api.getNews(id);
      if (res.code === 200 && res.data) {
        if (res.data.publishDate) {
          res.data.publishDate = moment(res.data.publishDate).format('YYYY.MM.DD');
        }
        this.detailData = res.data;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.top {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  height: auto;
  background-color: #f7f7f7;

  .news_box {
    cursor: pointer;
    display: flex;
    width: 1200px;
    margin: auto;
    height: 380px;

    // 宽高比
    .left {
      width: 40%;
      height: auto;
      // text-align: center;
      overflow: hidden;
      background-color: #fff;
    }

    .left img {
      height: 100%;
      width: 100%;
    }

    .right {
      position: relative;
      padding: 40px;
      width: 60%;
      background-color: #fff;

      .right_one {
        display: flex;
        // width: 100%;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        .title {
          font-size: 29px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 25px;
        }

        .title:hover {
          color: #497dcc;
        }

        .content {
          color: #575454;
          font-size: 17px;
        }
      }

      .right_two {
        position: absolute;
        bottom: 20px;
        padding-top: 20px;
        padding-bottom: 30px;
        font-size: 20px;
        border-top: 1px solid #ccc;
        width: 86%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

      }
    }

  }

}

.middle {
  .list_box {
    cursor: pointer;
    display: flex;
    width: 1200px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: auto;
    overflow: hidden;
    border-bottom: 1px solid #ccc;

    // aspect-ratio: 6/1;
    .m_img img {
      width: 200px;
      aspect-ratio: 3/2;
      overflow: hidden;
      border-radius: 4%;
    }

    .right2 {
      width: 100%;
      padding-left: 1.6vw;
      margin-left: 1vw;
      overflow: hidden;

      .title2 {
        font-size: 23px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title2:hover {
        color: #497dcc;
      }

      .content2 {
        font-size: 16px;
        margin-top: 15px;
        color: #999;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .button {
        margin-top: 40px;
      }
    }

    .pubdate {
      color: #497dcc;
      border-left: 1px solid #ccc;
      margin-left: 20px;
      padding: 20px 0 20px 30px;

      .year {
        font-family: 'STHupo';
        font-size: 18px;
        margin-bottom: 15px;
      }

      .date {
        font-family: 'STHupo';
        font-size: 35px;
      }
    }

  }

}

.bottom {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.body {
  width: 65%;
  background-color: #fff;
  // height: 2000px;
  padding: 0 50px;
  margin: 0 auto;
  // text-align: center;

  .title {
    font-size: 30px;
    border-bottom: 1px solid #ccc;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-left: 38px;
    margin-right: 38px;
      text-align: center;
  }

  .mid-title-p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    .mid-title-c {
      display: flex;
      flex-direction: row;
    }

    .mid-title-c div {
      color: #666;
      margin: 0 15px;
    }
  }
}

.content {
  padding-bottom: 80px;

  ::v-deep img {
    width: 100%;
  }
}

/deep/ .el-button--default {
  background-color: #497ddc;
  border-radius: 6px;
  font-size: 15px;
  color: white;
  padding: 5px 18px;
  font-weight: 400;

  &:hover {
    background-color: #4774c9;
  }
}

@media screen and (max-width: 1000px) {
  .top {
    .news_box {
      width: 90%;
      height: 300px;
    }
  }

  .middle {
    .list_box {
      width: 90%;
    }
  }
}

@media screen and (max-width: 700px) {
  .top {
    .news_box {
      width: 90%;
      display: block;
      height: auto;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        padding: 5vw;

        .right_one {
          .title {
            font-size: 32px;
          }

          .content {
            font-size: 20px;
          }
        }

        .right_two {
          font-size: 15px;
        }
      }
    }
  }

  .middle {
    .list_box {
      width: 90%;
      display: block;
      padding: 20px 0;

      .m_img img {
        width: 100%;
        border-radius: 0;
      }

      .right2 {
        padding: 20px 0 20px 10px;
        width: 75%;
        float: right;
        border-left: 1px solid #ccc;

        .button {
          float: right;
        }
      }

      .pubdate {
        padding: 20px 0;
        border-left: none;

        .year {
          font-size: 2.5vw;
        }

        .date {
          font-size: 5vw;
        }
      }
    }
  }
}
</style>
