<template>
  <div>
    <div v-if="pageInfo.viewType === 'CASE'">
      <Title :data="pageInfo"></Title>
      <Cases :data="pageInfo"></Cases>
    </div>
    <div v-else-if="pageInfo.viewType === 'TOOLBOX'">
      <Title :data="pageInfo"></Title>
      <Toolbox></Toolbox>
    </div>
    <div v-else-if="pageInfo.viewType === 'LIBRARY'">
      <Title :data="pageInfo"></Title>
      <Library></Library>
    </div>
    <div v-else-if="pageInfo.viewType === 'NEWS'">
      <Title :data="pageInfo"></Title>
      <News v-if="isShow"></News>
      <!-- <Newsdetail v-else></Newsdetail> -->
    </div>
    <div v-else-if="pageInfo.viewType === 'EDUCATION'">
      <Title :data="pageInfo"></Title>
      <Education></Education>
    </div>
    <div v-else-if="pageInfo.viewType === 'HOME'">
      <Home></Home>
    </div>
    <div v-else-if="pageInfo.viewType === 'INTERNAL'">
      <Iframe></Iframe>
    </div>
    <div v-else-if="pageInfo.viewType === 'MARKDOWN'">
      <DTitle :data="pageInfo"></DTitle>
      <Markdown :data="pageInfo.markdown"></Markdown>
      <Block :data="pageInfo.blocks" v-if="pageInfo.blocks"></Block>
      <!-- <Download :productDTOS="pageInfo.productDTOS" v-if="pageInfo.productDTOS"></Download> -->
    </div>
    <div v-else-if="pageInfo.viewType === 'DOWNLOAD'">
      <DTitle :data="pageInfo"></DTitle>
      <Download></Download>
    </div>
    <div v-else-if="pageInfo.viewType === 'TY_ACCOUNT'">
      <iframe v-if="this.$route.query.path" id="ticketIFrame" :src="
          'https://tongyuan.cc:9991/ticket/' +
          this.$route.query.path +
          '?access_token=' +
          token +
          '&product=' +
          product +
          '&software=' +
          software +
          '&sysinfo=' +
          sysinfo
          " style="
          background-size: 100% 100%;
          width: 100%;
          height: 800px;
          border: none;
        "></iframe>
      <iframe v-else id="ticketIFrame" :src="'https://tongyuan.cc:9991/ticket/mine' + '?access_token=' + token" style="
          background-size: 100% 100%;
          width: 100%;
          height: 800px;
          border: none;
        "></iframe>
    </div>
    <div v-else-if="pageInfo.viewType === 'SAFETY'">
      <DTitle :data="pageInfo"></DTitle>
      <Block :data="pageInfo.blocks" v-if="pageInfo.blocks"></Block>
    </div>
    <div v-else-if="this.$route.path.includes('videoPlay')">
      <VideoPlay></VideoPlay>
    </div>
    <div v-else>
      <SolutionTitle :data="pageInfo" v-if="pageInfo.router === '/solution'"></SolutionTitle>
      <Title :data="pageInfo" v-else-if="pageInfo.title"></Title>
      <Block :data="pageInfo.blocks" v-if="pageInfo.blocks"></Block>
    </div>
  </div>
</template>

<script>
import Title from '../title';
import DTitle from '../title/downloadtitle';
import SolutionTitle from '../title/secondindex.vue';
import Block from '../block';
import Cases from '../cases';
import Toolbox from '../toolbox';
import Library from '../library';
import Home from '../home';
import Iframe from '../iframe';
import Markdown from '../markdown';
import Download from '../download';
import Education from '../education';
import VideoPlay from '../education/videoplay';
import News from '../news/';
import { mapActions } from 'vuex';
export default {
  components: {
    Title,
    SolutionTitle,
    Block,
    Cases,
    Toolbox,
    Library,
    Home,
    Iframe,
    Markdown,
    Download,
    DTitle,
    Education,
    VideoPlay,
    News
  },
  data() {
    return {
      pageInfo: {},
      userInfo: null,
      token: '',
      isShow: true
    };
  },
  watch: {
    '$route.path': {
      handler() {
        this.changeTitle();
      }
    }
  },
  created() {
    this.userInfo = this.$utils.getStorage('userInfo');
    this.product = this.$route.query.product || '';
    this.software = this.$route.query.software || '';
    this.sysinfo = this.$route.query.sysinfo || '';
    this.getPage({ router: this.$route.path });
    this.token = this.$utils.getToken();
    // || this.$route.query.token
    console.log('this.token', this.token);
    document.documentElement.scrollTop = 0;
  },
  methods: {
    ...mapActions('app', ['setUserInfo']),
    changeTitle() {
      let title = this.pageInfo.title;
      document.title = title ? title + '-苏州同元软控' : '苏州同元软控';
    },
    async getUserInfo() {
      const res = await this.$api.userinfo();
      // store.dispatch('app/setUserInfo', res.data);
      this.setUserInfo(res.data);
    },
    async getPage(params) {
      const res = await this.$api.getPage(params);
      if (res.code == 200) {
        this.pageInfo = res.data;
        if (this.pageInfo.viewType === 'TY_ACCOUNT') {
          this.getUserInfo();
        }
        if (this.pageInfo.viewType === 'EXTERNAL') {
          if (this.token && this.pageInfo.router.includes('/applyLicense')) {
            let url = this.pageInfo.router + '?token=' + this.token;
            window.location.href(url);
          } else {
            window.location.href(this.pageInfo.router);
          }
        }
        this.changeTitle();
        console.log('this.$route.query', this.$route.query);
        console.log(this.pageInfo, 'pageinfo');
      }
    }
  }
};
</script>

<style></style>
