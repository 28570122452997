<template>
  <div style="background-color: #f4f7f9">
    <column :data="caseList" v-if="reg"></column>
    <card :data="typeList" v-else></card>
  </div>
</template>

<script>
import column from "@/components/block/columneight.vue";
import card from "@/components/block/cardthree.vue";
import router from "@/router";
export default {
  components: {
    card,
    column
  },
  props: {
    data: Object,
  },
  data() {
    return {
      typeList: {
        items: []
      },
      caseList: {
        items: []
      },
      reg: true
    };
  },
  watch: {
        '$route.path': {
            handler() {
                this.getContent();
            },
        }
    },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.reg = this.data.router.includes('/case/');
    console.log(this.reg);
    if (this.reg) {
      this.getCaseList(this.data.router)
    } else {
      this.getCaseType();
    }
    },
    async getCaseType() {
      const res = await this.$api.getCaseType();
      if (res.code == 200) {
        this.typeList.items = res.data;
      }
    },
    async getCaseList(path) {
      const res = await this.$api.getCaseList({ router: path });
      if (res.code == 200) {
        this.caseList.items = res.data;
      }
    }
  },
};
</script>

<style></style>
