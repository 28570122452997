<template>
    <div style="background-color: #f4f7f9;">
        <div class="body">
            <div class="left">
                <div class="filter">
                    <span class="condition">筛选条件</span>
                    <span class="reset" @click="resetAll()">重置</span>
                </div>
                <div id="product" class="class">
                    <div class="title-wrap">
                        <span class="title">产品</span>
                        <span @click="clearCondition('product')" v-show="request.productId" class="clear">清除</span>
                    </div>
                    <div class="border-line"></div>
                    <div class="productStyle" :class="{ active: request.productId === item.id }"
                        @click="handleTagClick(item.id, 'product')" v-for="(item, index) of productList" :key="index">
                        <span class="name">{{ item.name }}</span>
                        <div v-if="item.num !== 0">{{ item.num }}</div>
                    </div>
                </div>
                <div id="difficult" class="class">
                    <div class="title-wrap">
                        <span class="title">难度</span>
                        <span v-show="request.difficultId" class="clear" @click="clearCondition('difficult')">清除</span>
                    </div>
                    <div class="border-line"></div>
                    <div class="productStyle" :class="{ active: request.difficultId === item.id }"
                        @click="handleTagClick(item.id, 'difficult')" v-for="(item, index) of difficultList" :key="index">
                        <span class="name">{{ item.name }}</span>
                        <div v-if="item.num !== 0">{{ item.num }}</div>
                    </div>
                </div>
                <div id="project" class="class">
                    <div class="title-wrap">
                        <span class="title">专题</span>
                        <span v-show="request.projectId" class="clear" @click="clearCondition('project')">清除</span>
                    </div>
                    <div class="border-line"></div>
                    <div class="productStyle" :class="{ active: request.projectId === item.id }"
                        @click="handleTagClick(item.id, 'project')" v-for="(item, index) of projectList" :key="index">
                        <span class="name">{{ item.name }}</span>
                        <div v-if="item.num !== 0">{{ item.num }}</div>
                    </div>
                </div>
            </div>
            <div class="right" v-loading="loading">
                <div class="title">
                    <span>课程全集</span>
                </div>
                <div class="bd">
                    <ul v-if="content.length">
                        <li v-for="(item, index) in content" :key="index">
                            <Card :data="item" />
                        </li>
                    </ul>
                    <div v-else class="no-course">暂无教程</div>
                </div>
                <div class="bottom" v-if="total">
                    <el-pagination background :pageSizes="[6, 9]" :pageSize="request.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :currentPage="request.pageIndex" :total="total"
                        @current-change="pageCurrentChange" @size-change="pageSizeChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Card from './components/card.vue';
export default {
    components: {
        Card
    },
    data() {
        return {
            loading: false,
            request: {
                difficultId: 0,
                productId: 0,
                projectId: 0,
                pageIndex: 1,
                pageSize: 6
            },
            difficultList: [],
            productList: [],
            projectList: [],
            content: [],
            total: 0
        };
    },
    computed: {
        isTagActive() {
            return (data, type) => {
                const i = this.tags.findIndex(
                    item => item.id == data.id && item.type == type
                );
                return i > -1;
            };
        }
    },
    created() {
        this.getDifficultList();
        this.getProductList();
        this.getProjectList();
        this.request.projectId = this.$route.params.projectId;
        this.request.productId = this.$route.params.productId;
        this.request.difficultId = this.$route.params.difficultId;
        this.loadData();
    },
    methods: {
        handleTagClick(id, type) {
            if (type === 'project') {
                this.request.projectId = id;
            } else if (type === 'product') {
                this.request.productId = id;
            } else if (type === 'difficult') {
                this.request.difficultId = id
            }
            this.request.pageIndex = 1;
            // this.changeRoute();
            this.loadData();
        },
        // 移除标签
        clearCondition(type) {
            if (type === 'project') {
                this.request.projectId = 0;
            } else if (type === 'product') {
                this.request.productId = 0;
            } else if (type === 'difficult') {
                this.request.difficultId = 0;
            }
            this.request.pageIndex = 1;
            // this.changeRoute();
            this.loadData();
        },
        resetAll() {
            this.request.productId = 0;
            this.request.projectId = 0;
            this.request.difficultId = 0;
            this.request.pageIndex = 1;
            // this.changeRoute();
            this.loadData();
        },
        changeRoute() {
            let query = {
                ...(this.request.productId ? { productId: this.request.productId } : {}),
                ...(this.request.difficultId ? { difficultId: this.request.difficultId } : {}),
                ...(this.request.projectId ? { projectId: this.request.projectId } : {})
            };
            this.$router.replace({
                query
            });
        },
        async loadData() {
            this.loading = true;
            const res = await this.$api.pageListCourse(this.request)
                .catch(() => {
                    this.loading = false;
                });
            if (res.code == 200) {
                this.content = res.data;
                this.total = Number(res.total);
            }
            this.loading = false;
        },
        pageCurrentChange(value) {
            this.request.pageIndex = value;
            this.loadData();
        },
        // 分页数变化
        pageSizeChange(value) {
            this.request.pageSize = value;
            this.loadData();
        },
        async getDifficultList() {
            const res = await this.$api.getCourseDifficultList();
            if (res.code == 200) {
                this.difficultList = res.data;
            }
        },
        async getProductList() {
            const res = await this.$api.getCourseProductList();
            if (res.code == 200) {
                this.productList = res.data;
            }
        },
        async getProjectList() {
            const res = await this.$api.getCourseProjectList();
            if (res.code == 200) {
                this.projectList = res.data;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.body {
    height: auto;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.right {
    width: 77%;
    margin-left: 5%;
    margin-top: 50px;
    margin-bottom: 50px;

    .bd {
        min-height: 700px;

        .no-course {
            display: flex;
            justify-content: center;
            align-items: Center;
            font-size: 18px;
            color: #adacac;
            min-height: 600px;
        }
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        color: #a0a0a0;
        margin-bottom: 40px;
    }

    .bottom {
        text-align: center;
        clear: both;
    }
}

.right ul {
    width: 100%;
}

.right li {
    width: 31%;
    float: left;
    margin-right: 2%;
}

.left {
    width: 18%;
    margin-bottom: 50px;

    .filter {
        color: #5e5e5e;
        margin-top: 50px;
        display: flex;
    }

    .condition {
        flex: 1;
    }

    .reset {
        cursor: pointer;
        color: #888888;

        &:hover {
            color: #026fdc;
            text-decoration: underline;
        }
    }

    .class {
        margin-top: 40px;
        color: #171717;
    }

    .title-wrap {
        display: flex;
        align-items: center;
    }

    .title {
        flex: 1;
        font-size: 20px;
        font-weight: bold;
    }

    .clear {
        cursor: pointer;
        color: #888888;

        &:hover {
            color: #026fdc;
            text-decoration: underline;
        }
    }

    .border-line {
        border-top: 1px solid #cccccc;
        margin: 10px 0;
    }

    .productStyle {
        color: #666666;
        line-height: 30px;
        display: flex;

        &.active {
            color: #026fdc;
            font-weight: bold;
        }
    }

    .name {
        font-size: 14px;
        flex: 1;
        cursor: pointer;
    }
}

@media screen and (max-width: 700px) {
    .right {
        width: 70%;
    }

    .right ul {
        width: 100%;
        margin: 0 auto;
    }

    .right li {
        width: 100%;
    }

    .left {
        width: 30%;

        .name {
            font-size: 12px;
        }
    }
}

/deep/ .el-loading-mask {
    background-color: rgba(244, 247, 249, 0.5);
}
</style>
